import { List } from 'vant'
import { mapGetters } from 'vuex'

const leadModule_0 = () => import('@/pages/modules/lead/leadModule_0.vue') //代码切割
const leadModule_1 = () => import('@/pages/modules/lead/leadModule_1.vue') //代码切割
const leadModule_2 = () => import('@/pages/modules/lead/leadModule_2.vue') //代码切割
const leadModule_3 = () => import('@/pages/modules/lead/leadModule_3.vue') //代码切割
const leadModule_4 = () => import('@/pages/modules/lead/leadModule_4.vue') //代码切割
const leadModule_5 = () => import('@/pages/modules/lead/leadModule_5.vue') //代码切割
const leadModule_6 = () => import('@/pages/modules/lead/leadModule_6.vue') //代码切割
const leadModule_7 = () => import('@/pages/modules/lead/leadModule_7.vue') //代码切割
const leadModule_8 = () => import('@/pages/modules/lead/leadModule_8.vue') //代码切割
const leadModule_9 = () => import('@/pages/modules/lead/leadModule_9.vue') //代码切割
const leadModule_10 = () => import('@/pages/modules/lead/leadModule_10.vue') //代码切割
const leadModule_11 = () => import('@/pages/modules/lead/leadModule_11.vue') //代码切割
const leadModule_12 = () => import('@/pages/modules/lead/leadModule_12.vue') //代码切割

const collectionModule_0 = () => import('@/pages/modules/collection/collectionModule_0.vue') //代码切割
const collectionModule_1 = () => import('@/pages/modules/collection/collectionModule_1.vue') //代码切割
const collectionModule_2 = () => import('@/pages/modules/collection/collectionModule_2.vue') //代码切割
const collectionModule_3 = () => import('@/pages/modules/collection/collectionModule_3.vue') //代码切割
const collectionModule_4 = () => import('@/pages/modules/collection/collectionModule_4.vue') //代码切割
const collectionModule_5 = () => import('@/pages/modules/collection/collectionModule_5.vue') //代码切割
const collectionModule_6 = () => import('@/pages/modules/collection/collectionModule_6.vue') //代码切割
const collectionModule_7 = () => import('@/pages/modules/collection/collectionModule_7.vue') //代码切割
const collectionModule_8 = () => import('@/pages/modules/collection/collectionModule_8.vue') //代码切割
const collectionModule_9 = () => import('@/pages/modules/collection/collectionModule_9.vue') //代码切割
const collectionModule_10 = () => import('@/pages/modules/collection/collectionModule_10.vue') //代码切割
const collectionModule_11 = () => import('@/pages/modules/collection/collectionModule_11.vue') //代码切割
const collectionModule_12 = () => import('@/pages/modules/collection/collectionModule_12.vue') //代码切割

export default {
	components: {
		[List.name]: List, //按需加载vant
		leadModule_12,
		leadModule_11,
		leadModule_10,
		leadModule_9,
		leadModule_8,
		leadModule_7,
		leadModule_6,
		leadModule_5,
		leadModule_4,
		leadModule_3,
		leadModule_2,
		leadModule_1,
		leadModule_0,

		collectionModule_12,
		collectionModule_11,
		collectionModule_10,
		collectionModule_9,
		collectionModule_8,
		collectionModule_7,
		collectionModule_6,
		collectionModule_5,
		collectionModule_4,
		collectionModule_3,
		collectionModule_2,
		collectionModule_1,
		collectionModule_0,
	},
	data() {
		return {
			list: [],
			hasNext: false,
			loading: false, // 控制上拉加载的加载状态
			finished: false, // 是否已加载完全部数据
			bomTitle: '-- END --', // 上拉加载完成时的文本
			page: 1,
			isinitialization: false,
			isData: false,
		}
	},
	computed: {
		...mapGetters({
			host: 'host',
			themeConfig: 'themeConfig',
			adsense: 'adsenseConfig',
			showDebug: 'showDebug',
			domainConfig: 'domainConfig',
			deviceType: 'deviceType',
		}),
	},
	methods: {
		getAdjustedItems(config, list, adPositionField, leadTypeField, collectionTypeField, typeValue) {
			let items = []
			let adsPos = config[adPositionField] || []
			list.forEach((item, index) => {
				// 检查当前位置是否应插入广告
				if (adsPos.includes(index + 1)) {
					items.push({ adtype: 'ad', position: index + 1 })
				}
				// 根据 item 的 type 决定使用 leadModule 还是 collectionModule
				let componentType = item.cardType === typeValue ? `leadModule_${config[leadTypeField]}` : `collectionModule_${config[collectionTypeField]}`
				items.push({ adtype: 'component', data: item, componentType })
			})
			return items
		},
		async onLoad() {
			console.log('触发onload方法')
			await this.getList()
		},
		async getList() {
			// console.log(`this.host-->${this.host}`);
			// 获取当前 URL
			let url = window.location.href
			// 移除 http:// 或 https:// 前缀
			let formattedUrl = url.replace(/^https?:\/\//, '')
			// 去掉路径和查询参数，只保留域名部分
			formattedUrl = formattedUrl.split('/')[0]
			// console.log(`url:${url} 格式化后的url:${formattedUrl}`)
			// 根据条件设置域名
			let domain = this.host ? this.host : formattedUrl
			console.log(`请求domain:${domain}`)
			domain = domain == 'localhost:8080' ? 'techcrunchabc.click' : domain

			try {
				const res = await this.$global.server.getList({
					pageNum: this.page,
					pageSize: 30,
					domain: domain, //yarn serve使用时兼容
				})
				console.log(res)
				this.page += 1
				this.loading = false
				this.finished = !res.data.hasNext

				if (res.code === 200) {
					let data = res.data.articleSummaryList
					this.list.push(...data)
					if (this.list.length > 0) {
						this.isData = true
					}
					this.hasNext = res.data.hasNext
				}

				if (!this.isinitialization) {
					this.isinitialization = true
					this.$eventrackFb('news_list_init', 'expose', {
						pve_cur: '/h5page/news',
						state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0,
					})
				}
			} catch (err) {
				console.log(err)
				this.isinitialization = true
				this.finished = true
				this.$eventrackFb('news_list_init_error', 'expose', {
					pve_cur: '/h5page/news',
					state: -1,
				})
			}
		},
	},
}
